<template>
	<v-card
		class="mx-auto mb-5 flex-grow-1 flex-shrink-0 d-flex flex-column"
		style="width: 100%"
		max-width="1200"
		color="deep-orange darken-3"
		rounded="xl"
	>
		<v-toolbar class="flex-grow-0" color="deep-orange darken-4">
			<v-avatar size="45px" class="mr-2">
				<v-img alt="Avatar" src="@/assets/images/icon/logo-oversos-second-2x.png" />
			</v-avatar>
			<v-toolbar-title>
				<div class="d-flex flex-column">
					<span class="text-h6"><span style="font-family: Tahoma">OverSOS</span></span>
					<span class="text-subtitle-1">
						<v-icon small color="green">mdi-shield-check-outline</v-icon> {{ $t('announcements.officialAnnouncement') }}
					</span>
				</div>
			</v-toolbar-title>
			<v-spacer />
		</v-toolbar>

		<v-card-title>{{ announcement.title }}</v-card-title>
		<v-card-subtitle>
			<time :datetime="announcement.timestamp">{{ humanTime }}</time>
		</v-card-subtitle>
		<v-card-text
			class="text-body-1 text-justify flex-grow-1"
			style="white-space: pre-line"
			ref="content"
			v-html="callLinkify(announcement.content)"
		/>

		<v-card-actions class="flex-grow-0">
			<v-btn text small icon @click="callAproveAnnouncement(true)">
				<v-icon>{{ aproved ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
			</v-btn>
			<v-badge class="mr-2" :content="thumbsUp ? thumbsUp : '0'" :value="thumbsUp ? thumbsUp : '0'" inline overlap />

			<v-btn text small icon @click="callAproveAnnouncement(false)">
				<v-icon>{{ disaproved ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon>
			</v-btn>
			<v-badge :content="thumbsDown ? thumbsDown : '0'" :value="thumbsDown ? thumbsDown : '0'" inline overlap />
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

function linkify(inputText) {
	var replacedText, replacePattern1, replacePattern2, replacePattern3

	//URLs starting with http://, https://, or ftp://
	replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
	replacedText = inputText.replace(
		replacePattern1,
		'<a href="$1" target="_blank" style="text-decoration: none; color: #0003ff; font-weight: 600;">$1</a>'
	)

	//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
	replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
	replacedText = replacedText.replace(
		replacePattern2,
		'$1<a href="http://$2" target="_blank" style="text-decoration: none; color: #0003ff; font-weight: 600;">$2</a>'
	)

	//Change email addresses to mailto:: links.
	replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
	replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="text-decoration: none; color: #0003ff; font-weight: 600;">$1</a>')

	return replacedText
}

export default {
	name: 'FeedAnnouncement',
	props: {
		announcement: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			aproved: this.announcement.aproved,
			disaproved: this.announcement.disaproved,
			thumbsUp: this.announcement.thumbsUp,
			thumbsDown: this.announcement.thumbsDown,
		}
	},
	created() {
		this.callLinkify()
	},
	computed: {
		humanTime() {
			return this.$moment(this.announcement.timestamp).fromNow()
		},
		...mapGetters({
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		callLinkify(content) {
			return content ? linkify(content) : content
		},
		callAproveAnnouncement(toBeAproved) {
			if ((this.aproved && toBeAproved) || (this.disaproved && !toBeAproved)) {
				this.undoAproveAnnouncement({ announcement: this.announcement }).then((data) => {
					if (data.success) {
						this.aproved = false
						this.disaproved = false
						if (toBeAproved) {
							this.thumbsUp -= 1
						} else {
							this.thumbsDown -= 1
						}
					}
				})
			} else if (this.setupInfo.verified.value) {
				this.aproveAnnouncement({ aproved: toBeAproved, announcement: this.announcement }).then((data) => {
					if (data.success) {
						if (toBeAproved) {
							if (this.disaproved) {
								this.thumbsDown -= 1
							}
							this.aproved = true
							this.disaproved = false
							this.thumbsUp += 1
						} else {
							if (this.aproved) {
								this.thumbsUp -= 1
							}
							this.aproved = false
							this.disaproved = true
							this.thumbsDown += 1
						}
					}
				})
			} else {
				console.log('needs logged in user')
			}
		},
		...mapActions('feed', ['aproveAnnouncement', 'undoAproveAnnouncement']),
	},
}
</script>
